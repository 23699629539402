<template>
  <v-dialog v-model="dialog" max-width="450">
    <v-card>
      <template v-if="isImportError">
        <v-card-title> エラー </v-card-title>

        <duplicate-items
          v-show="!hasGetNextCode && csvBlank.code.length > 0"
          message="ファイル内のコードが空です。"
          :items="csvBlank.code"
          :format="formatCode"
        />

        <duplicate-items
          v-show="csvDuplicate.code.length > 0"
          message="ファイル内のコードが重複しています。"
          :items="csvDuplicate.code"
          :format="formatCode"
        />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn @click="close">
            {{ $t('message.modal_close_btn') }}
          </v-btn>
        </v-card-actions>
      </template>

      <template v-else>
        <v-toolbar dark height="40" flat class="mb-3"> 確認 </v-toolbar>

        <duplicate-items
          v-show="dbDuplicate.code.length > 0"
          message="登録済みののコードと重複しています。(重複したデータは上書きされます)"
          :items="dbDuplicate.code"
          :format="formatCode"
        />

        <duplicate-items
          v-show="hasGetNextCode && csvBlank.code.length > 0"
          message="ファイル内のコードが空です。(コードは登録時に自動で付与されます)"
          :items="csvBlank.code"
          :format="formatCode"
        />

        <duplicate-items
          v-show="dbDuplicate.name.length > 0"
          message="登録済みのの名称と重複しています。"
          :items="dbDuplicate.name"
          :format="formatName"
        />

        <duplicate-items
          v-show="csvBlank.name.length > 0"
          message="ファイル内の名称が空です。"
          :items="csvBlank.name"
          :format="formatName"
        />

        <duplicate-items
          v-show="csvDuplicate.name.length > 0"
          message="ファイル内の名称が重複しています。"
          :items="csvDuplicate.name"
          :format="formatName"
        />

        <v-card-text class="px-4 py-2">
          ファイルを確認の上、よろしければインポートをクリックしてください。
        </v-card-text>

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn @click="close">
            {{ $t('message.modal_cancel_btn') }}
          </v-btn>
          <v-btn color="primary" @click="onClick">
            {{ $t('message.modal_import_btn') }}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import DuplicateItems from '@/pages/master/@components/@shared/DuplicateItems/DuplicateItems'

export default {
  components: {
    DuplicateItems,
  },
  props: {
    hasGetNextCode: {
      type: Boolean,
      default: false,
    },
    csvDuplicate: {
      type: Object,
      required: true,
    },
    dbDuplicate: {
      type: Object,
      required: true,
    },
    csvBlank: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    isImportError() {
      return (
        (!this.hasGetNextCode && this.csvBlank.code.length > 0) ||
        this.csvDuplicate.code.length > 0
      )
    },
  },
  mounted() {},
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    onClick() {
      this.$emit('onClick')
      this.close()
    },
    formatName(item) {
      return item.name
    },
    formatCode(item) {
      return item.code
    },
    formatNameCode(item) {
      return item.name_code
    },
  },
}
</script>
