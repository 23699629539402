<template>
  <v-dialog v-model="dialog" :max-width="maxWidth">
    <v-card class="sweeep-dialog">
      <v-card-title v-text="$t('message.edit')" />
      <v-card-text>
        <v-form ref="formEdit">
          <slot name="form">
            <v-radio-group v-model="inputs.status" row>
              <v-radio color="blue" label="表示" :value="1" />
              <v-radio color="blue" label="非表示" :value="2" />
            </v-radio-group>
            <v-text-field
              v-model="inputs.code"
              dense
              outlined
              filled
              :label="$t('message.code')"
              :rules="inputCodeRules"
              required
            />
            <v-text-field
              v-model="inputs.name"
              dense
              outlined
              filled
              :label="label"
              :rules="inputRules"
              required
            />
          </slot>
          <slot name="additionalForm" />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click.native="dialog = false">
          {{ $t('message.modal_cancel_btn') }}
        </v-btn>
        <v-btn color="primary" @click="onClick()">
          {{ $t('message.modal_save_btn') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    inputs: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '400',
    },
  },
  data() {
    var t = this
    return {
      dialog: false,
      inputCodeRules: [
        (v) => !!v || t.$t('message.form_input_required'),
        (v) => v.length <= 255 || '255' + t.$t('message.character_max_length'),
      ],
      inputRules: [
        (v) => !!v || t.$t('message.form_input_required'),
        (v) =>
          (v && v.replace(/\s/g, '').length >= 1) ||
          t.$t('message.form_input_required'),
        (v) => v.length <= 255 || '255' + t.$t('message.character_max_length'),
      ],
    }
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    onClick() {
      this.$emit('onClick')
    },
    validate() {
      return this.$refs.formEdit.validate()
    },
    resetValidation() {
      if (this.dialog) {
        this.$nextTick(function () {
          this.$refs.formEdit.resetValidation()
        })
      }
    },
    reset() {
      this.$refs.formEdit.reset()
    },
  },
}
</script>
