<template>
  <div>
    <dialog-edit-sort-num
      ref="dialogEditSortNum"
      v-model="editingSortNum"
      @submit="saveSortNum()"
    />

    <v-card-title class="py-0 px-6">
      <v-btn
        v-if="checkPrivilege(name + ':create')"
        color="primary"
        dark
        @click.stop="$emit('click:create')"
      >
        <s-icon icon="feather-plus" size="lg" class="tw-mr-1" />
        {{ $t('message.add') }}
      </v-btn>

      <v-btn
        v-if="checkPrivilege(name + ':update')"
        class="ml-2"
        @click.stop="clickBulkButton('click:bulk_edit')"
      >
        {{ $t('message.bulk_edit') }}
      </v-btn>

      <v-btn
        v-if="checkPrivilege(name + ':delete')"
        class="ml-2"
        @click.stop="clickBulkButton('click:bulk_delete')"
      >
        {{ $t('message.bulk_delete') }}
      </v-btn>

      <v-btn
        v-if="checkPrivilege(name + ':create')"
        class="ml-2"
        @click.stop="importCsvBtn()"
      >
        {{ $t('message.import_csv_btn') }}
      </v-btn>
      <input
        :id="name + '_import_csv'"
        type="file"
        class="sweeep-import-csv-hidden"
        @change="$emit('click:read_csv', $event)"
      />

      <v-btn
        v-if="checkPrivilege(name + ':create')"
        v-tooltip="'インポート用のデータサンプルを取得'"
        class="ml-2"
        text
        @click.stop="$emit('click:get_sample')"
      >
        {{ $t('message.accounting_get_sample') }}
      </v-btn>

      <v-btn
        v-if="checkPrivilege(name + ':export')"
        class="ml-2"
        @click.stop="$emit('click:export')"
      >
        {{ $t('message.master_export') }}
      </v-btn>

      <v-btn
        v-if="checkPrivilege(name + ':update')"
        v-tooltip="'表示順を一括で変更します'"
        class="ml-2"
        @click.stop="$emit('click:update_sort')"
      >
        <i class="ti-exchange-vertical mr-2" />
        {{ $t('message.save_sort_num') }}
      </v-btn>

      <v-spacer />
      <v-text-field
        v-model="search"
        placeholder="検索"
        filled
        dense
        rounded
        class="mt-5"
        clearable
        clear-icon="feather-x-circle"
      />
    </v-card-title>

    <v-data-table
      id="master-table"
      v-model="selected"
      class="px-6"
      :height="height"
      :items="items"
      :headers="headers"
      :search="search"
      show-select
      item-key="id"
      fixed-header
      hide-default-footer
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @pagination="onPagenation"
      @page-count="pageCount = $event"
      @current-items="getfilteredItems"
    >
      <template #header.data-table-select="{}">
        <select-all-header-cell
          :items="items"
          :all-selected="allSelected"
          :indeterminate="indeterminate"
          @click:item="onClickSelectAll($event.value, $event.message)"
        />
      </template>

      <template #item.sort_num="{ item }">
        <div
          v-if="checkPrivilege(name + ':update')"
          v-tooltip="$t('message.edit_sort_num')"
          class="sort_num"
          @click="openSortNum(item)"
        >
          {{ item.sort_num }}
        </div>
        <div v-else>
          {{ item.sort_num }}
        </div>
      </template>

      <template #item.status="{ item }">
        <s-chip v-if="item.status == 1" color="blue-500" class="px-4">
          表示
        </s-chip>
        <s-chip v-if="item.status == 2" color="red-500" class="px-3">
          非表示
        </s-chip>
      </template>

      <template #item.action="{ item }">
        <div class="d-flex justify-space-around">
          <v-btn
            v-if="checkPrivilege(name + ':update')"
            small
            rounded
            color="primary"
            @click="$emit('click:edit', item)"
          >
            {{ $t('message.table_edit_btn') }}
          </v-btn>
          <v-btn
            v-if="checkPrivilege(name + ':delete')"
            small
            rounded
            color=""
            @click="$emit('click:delete', item)"
          >
            {{ $t('message.table_delete_btn') }}
          </v-btn>
        </div>
      </template>

      <template #item.rate="{ item }">
        <slot name="item.rate" :item="item" />
      </template>

      <template v-if="!loading" #no-data>
        <v-alert :value="true" class="mt-3" color="yellow" icon="warning">
          {{ $t('message.noResult') }}
        </v-alert>
      </template>
    </v-data-table>

    <v-toolbar
      flat
      height="60"
      class="mt-3 pl-2"
      style="width: 100%; border-top: 1px solid #ccc"
    >
      <v-col cols="6" class="pa-0">
        <v-card flat tile class="d-flex align-content-center flex-wrap">
          <v-card flat class="align-self-center mr-3">
            <div class="fs-10 mb-1">表示件数</div>
            <v-autocomplete
              v-model="itemsPerPage"
              :items="itemsPerPageOptions"
              dense
              filled
              hide-details
              item-text="text"
              item-value="value"
              class="more-dense"
              style="width: 100px"
              :clearable="false"
              @change="$emit('change:items-per-page', itemsPerPage)"
            />
          </v-card>

          <v-card flat class="align-self-center mr-3">
            <div class="fs-10 mb-1">ページ</div>
            <v-autocomplete
              v-model="page"
              :items="pageCountItems"
              dense
              filled
              hide-details
              class="more-dense"
              style="width: 100px"
              :clearable="false"
            />
          </v-card>

          <v-card flat class="align-self-center">
            <div class="fs-10 mb-1">表示中</div>
            <v-chip small label style="padding: 14px">
              {{ pageInfo.start }} - {{ pageInfo.end }} / {{ itemsLength }}
            </v-chip>
          </v-card>
        </v-card>
      </v-col>

      <v-col cols="6" class="text-right pa-0">
        <v-pagination
          v-model="page"
          circle
          light
          style="width: auto"
          :length="pageCount"
        />
      </v-col>
    </v-toolbar>
  </div>
</template>

<script>
import { scrollTableToTop } from 'Helpers/table/scroll'
import SelectAllHeaderCell from 'Components/Table/SelectAllHeaderCell'
import Snackbar from 'Helpers/snackbar/index'
import DialogEditSortNum from '@/pages/master/@components/@shared/DialogEditSortNum/DialogEditSortNum'

export default {
  components: {
    SelectAllHeaderCell,
    DialogEditSortNum,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: 'calc(100vh - 215px)',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      itemsPerPage: 50,
      pageCount: 0,
      itemsLength: 0,
      pageInfo: {
        start: 0,
        end: 0,
      },
      itemsPerPageOptions: [
        { value: 50, text: '50件' },
        { value: 100, text: '100件' },
        { value: 200, text: '200件' },
      ],
      selected: [],
      filteredItems: [],
      selectedItem: {},
      editingSortNum: '',
    }
  },
  computed: {
    pageCountItems() {
      return Array.from({ length: this.pageCount }, (v, k) => k + 1)
    },
    allSelected: function () {
      return this.items.length == this.selected.length
    },
    indeterminate: function () {
      return !this.allSelected && this.selected.length > 0
    },
  },
  methods: {
    onPagenation(pageInfo) {
      this.itemsLength = pageInfo.itemsLength
      this.pageInfo.start = pageInfo.pageStart + 1
      this.pageInfo.end = pageInfo.pageStop
      scrollTableToTop('master-table')
    },
    resetSelected() {
      this.selected.splice(-this.selected.length)
    },
    getfilteredItems(event) {
      this.filteredItems = event
    },
    onClickSelectAll(selectType, message) {
      //selectType  1:全選択 2:現在ページ選択 3:全解除 4:現在ページ解除
      switch (selectType) {
        case 1: {
          this.selected = []
          for (let item of this.items) {
            this.selected.push(item)
          }
          break
        }
        case 2: {
          this.filteredItems.forEach((item) => {
            if (!this.selected.includes(item)) {
              this.selected.push(item)
            }
          })
          break
        }
        case 3: {
          this.selected.splice(-this.selected.length)
          break
        }
        case 4: {
          this.filteredItems.forEach((item) => {
            if (this.selected.includes(item)) {
              this.selected = this.selected.filter((v) => v.id != item.id)
            }
          })
          break
        }
      }
      Snackbar.success(message)
    },
    importCsvBtn() {
      $('#' + this.name + '_import_csv').click()
    },
    getSelectedIds() {
      try {
        return this.selected.map((item) => item.id)
      } catch (e) {
        console.log(e)
      }
    },
    clickBulkButton(event) {
      const ids = this.getSelectedIds()
      if (ids.length > 0) {
        this.$emit(event, ids)
      } else {
        Snackbar.error(this.$t('message.no_selected_data'))
      }
    },
    openSortNum(item) {
      try {
        this.selectedItem = item
        this.editingSortNum = String(item.sort_num)
        this.$refs.dialogEditSortNum.open()
      } catch (e) {
        console.log(e)
      }
    },
    saveSortNum() {
      try {
        let item = this.selectedItem
        item.sort_num = this.editingSortNum
        this.$emit('click:updateSort')
        this.$refs.dialogEditSortNum.close()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
